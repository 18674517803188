.red {
    border: 1px solid red !important;
}

.red:focus {
    border: 1px solid red;
}

input[type="file"] {
    display: none;
}

.uploadButton {
    color: #fff;
    background-color: #464dee;
    border-color: #464dee;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center
}

.uploadButton:hover {
    cursor: pointer;
}

.parentColor {
    overflow: hidden;
}

::placeholder {
    color: '#e8e8e8'
}

.noProductsFound {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 20px;
}

.border {
    border-width: 1px;
    border-color: #ccc;
    border-style: solid;
    padding: 25px
}

.borderApplyColor {
    border: solid #ccc 1px;
}

.checkSuggestion {
    border: solid 0.5px #ccc;
    padding: 10px;
}

.checkSuggestion:hover {
    cursor: pointer
}
.react-autosuggest__container>input {
    width: 100%;
    height: 48px;
    border: solid 1px #EAEAEA;
    padding: 15px;
}
.react-autosuggest__suggestions-container--open{
    position: absolute;
    z-index: 99999;
    background: #fff;
    width: 97%;
    margin: 0px;
    padding: 0px;
    height: 0px;
}
.react-autosuggest__suggestions-container--open>ul{
    margin-left: 0;
    padding-left: 0;
}
.react-autosuggest__suggestions-container--open>ul>li{
    list-style-type: none;
    background: #f1f1f1;
    color:#000
}
input[type=number]::-webkit-inner-spin-button, 
input[type=number]::-webkit-outer-spin-button { 
  -webkit-appearance: none; 
  margin: 0; 
}

.heading_mine th{
    font-weight: 800 !important;
    background:#001737;
    color:#e5e5e5 !important;
    /* padding:20px 0px; */
    border:#fff 1px solid;
    text-transform: uppercase;
    text-align:center;
    border-radius: 5px;
    font-size: .875rem !important;
}
.b_font{
    font-size: .75rem !important;
}
.table_heading_view tbody tr td {
    text-align:center;
}
table {
    font-family: arial, sans-serif;
    border-collapse: collapse;
    width: 100%;
}

.headTable {
    border: 1px solid #dddddd;
    text-align: left;
    padding: 8px;
}
.text-align-center{
    text-align: center;
}
.company_name {
    color: maroon;
    font-style: italic;
}
.text-align-end{
    text-align: end;
}
.fontCheck{
    font-size: .875rem !important;
}

.temp{
    display:flex;
    flex-direction:column;
    align-items: space-between;
}

.cross-batch{
    position: absolute;
    left: 0;
    top: -7px;
    height: 15px;
    width: 15px;
    border-radius: 5px;
}
.batch-parent{
    background-color: blue;
    padding: 10px;
    border-radius: 20px;
    display: flex;
    flex-direction: row;
}
.batch-line-parent{
    display: flex;
    flex-direction: row;
}
.batch-line{
    height: 100%;
    width: 1px;
    background-color: #ffffff;
}
.manageFont{
    font-size: 13px !important;
}
.grid-item {
    background-color: rgba(255, 255, 255, 0.8);
    border: 1px solid rgba(0, 0, 0, 0.8);
    text-align: center;
    margin-top: 5px;
  }