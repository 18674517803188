
.black{
  color: #000000 !important;
}

.black:hover{
  color: #464dee !important;
}

.invalid-field{
  border:1px solid #f00 !important;
}
.error-msg{
  color:#f00;
  font-size: 0.75rem;
  line-height: 1.4rem;
  text-transform: capitalize;;
}
.valid-msg{
  color:#088000;
  font-size: 0.75rem;
  line-height: 1.4rem;
  text-transform: capitalize;
}

.cursor:hover{
  cursor: pointer
}

.placeholder::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #aaa;
  opacity: 1; /* Firefox */
}